#root {
  position: absolute;
  max-width: 700px;
  margin: 0 auto;
  padding: 2rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

a {
  text-decoration: none;
  color: black;
}

.site-container {
  display: flex;
  flex-direction: row;
  width: 700px;
}

.info {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.name {
  font-size: 43px;
  letter-spacing: 2px;
  line-height: 20px;
}
.job {
  font-size: 38px;
}
.email {
  font-size: 20px;
  text-decoration: none;
}

.image {
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

@media only screen and (max-width: 780px) {
  .site-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
  }

  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .image {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 40px;
  }
  .name {
    font-size: 25px;
    letter-spacing: 2px;
    line-height: 20px;
  }
  .job {
    font-size: 20px;
  }
  .email {
    font-size: 16px;
    text-decoration: none;
  }
}
