.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  border-radius: 20px;
  background-color: var(--colorOne);
  width: 700px;
}

.logo {
  width: 50px;
  margin: 25px;
  transition: all 0.2s;
  object-fit: contain;
  filter: saturate(0%);
}

.logo:hover {
  transform: scale(1.8);
  -webkit-filter: none;
  filter: none;
}

@media only screen and (max-width: 780px) {
  .logo-container {
    width: 90vw;
  }
}
